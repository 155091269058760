exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aktualnosci-archiwum-js": () => import("./../../../src/pages/aktualnosci/archiwum.js" /* webpackChunkName: "component---src-pages-aktualnosci-archiwum-js" */),
  "component---src-pages-aktualnosci-js": () => import("./../../../src/pages/aktualnosci.js" /* webpackChunkName: "component---src-pages-aktualnosci-js" */),
  "component---src-pages-archiwalia-[slug]-js": () => import("./../../../src/pages/archiwalia/[slug].js" /* webpackChunkName: "component---src-pages-archiwalia-[slug]-js" */),
  "component---src-pages-archiwalia-js": () => import("./../../../src/pages/archiwalia.js" /* webpackChunkName: "component---src-pages-archiwalia-js" */),
  "component---src-pages-bibliografia-js": () => import("./../../../src/pages/bibliografia.js" /* webpackChunkName: "component---src-pages-bibliografia-js" */),
  "component---src-pages-chatbot-js": () => import("./../../../src/pages/chatbot.js" /* webpackChunkName: "component---src-pages-chatbot-js" */),
  "component---src-pages-filmografia-js": () => import("./../../../src/pages/filmografia.js" /* webpackChunkName: "component---src-pages-filmografia-js" */),
  "component---src-pages-historia-adaptacje-js": () => import("./../../../src/pages/historia/adaptacje.js" /* webpackChunkName: "component---src-pages-historia-adaptacje-js" */),
  "component---src-pages-historia-galeria-js": () => import("./../../../src/pages/historia/galeria.js" /* webpackChunkName: "component---src-pages-historia-galeria-js" */),
  "component---src-pages-historia-js": () => import("./../../../src/pages/historia.js" /* webpackChunkName: "component---src-pages-historia-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-notatnik-fotograficzny-[slug]-js": () => import("./../../../src/pages/notatnik-fotograficzny/[slug].js" /* webpackChunkName: "component---src-pages-notatnik-fotograficzny-[slug]-js" */),
  "component---src-pages-notatnik-fotograficzny-js": () => import("./../../../src/pages/notatnik-fotograficzny.js" /* webpackChunkName: "component---src-pages-notatnik-fotograficzny-js" */),
  "component---src-pages-photo-js": () => import("./../../../src/pages/photo.js" /* webpackChunkName: "component---src-pages-photo-js" */),
  "component---src-pages-prace-[slug]-js": () => import("./../../../src/pages/prace/[slug].js" /* webpackChunkName: "component---src-pages-prace-[slug]-js" */),
  "component---src-pages-prace-js": () => import("./../../../src/pages/prace.js" /* webpackChunkName: "component---src-pages-prace-js" */),
  "component---src-pages-prace-wszystkie-js": () => import("./../../../src/pages/prace/wszystkie.js" /* webpackChunkName: "component---src-pages-prace-wszystkie-js" */),
  "component---src-pages-projekt-js": () => import("./../../../src/pages/projekt.js" /* webpackChunkName: "component---src-pages-projekt-js" */),
  "component---src-pages-szukaj-js": () => import("./../../../src/pages/szukaj.js" /* webpackChunkName: "component---src-pages-szukaj-js" */),
  "component---src-templates-kolekcja-js": () => import("./../../../src/templates/kolekcja.js" /* webpackChunkName: "component---src-templates-kolekcja-js" */),
  "component---src-templates-map-js": () => import("./../../../src/templates/map.js" /* webpackChunkName: "component---src-templates-map-js" */),
  "component---src-templates-news-js": () => import("./../../../src/templates/news.js" /* webpackChunkName: "component---src-templates-news-js" */),
  "component---src-templates-person-js": () => import("./../../../src/templates/person.js" /* webpackChunkName: "component---src-templates-person-js" */)
}

